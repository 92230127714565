import React from "react";
import clsx from "clsx";
interface Props {
  cardClassName?: string;
  className?: string;
  innerClassName?: string;
  variant?: "narrow" | "normal";
  children: React.ReactNode;
}
export const Card: React.FC<Props> = ({
  innerClassName,
  cardClassName,
  children,
  className,
  variant = "normal"
}) => {
  return <div className={clsx("rounded-2xl py-14 px-4", {
    "bg-white": !cardClassName?.includes("bg-")
  }, cardClassName, className)} data-sentry-component="Card" data-sentry-source-file="Card.tsx">
      <div className={clsx(Variants[variant], innerClassName)}>{children}</div>
    </div>;
};
const Variants = {
  narrow: "mx-auto max-w-2xl",
  normal: ""
};